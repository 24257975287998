// (Data service)
//Now we define a service for accessing data

import axios from 'axios';

const API_URL = 'https://silverdormanager.univerhome.com/api/fth-api/';
//const API_URL = 'http://localhost/speechor/api/fth-api/';

class UserService {
  
  	ordernow(item, referrer) {
      return axios.post(API_URL,  {
                          "name":"ordernow",
                          "param":{
                                  "product": item,
                                  "referrer": referrer
                          }
                      },
                      { headers: {
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getprice() {
          return axios.post(API_URL,  {
                              "name":"getprice",
                              "param":{
                                      "price": 'price',           
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
        }
    
}

export default new UserService();