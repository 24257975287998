import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({encodingType: 'rc4', isCompression: false});
ls.removeAll();
import UserService from '../services/user.service';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    something:true,
    mainprice:[
            {text: "Loading...", value: 1255},
          ],
    price:[
            {id: 225, price: 29.95, product: "Silverdor Micro Pack"},
            {id: 228, price: 39.95, product: "Silverdor Medium Pack"},
            {id: 229, price: 49.95, product: "Silverdor Mega Pack"},
            {id: 733, price: 85.95, product: "Silverdor Business Pack"}
          ],
    upgrade:[ {text: "Upgrade from Micro to Medium Package[$15]", value: 1255},
              {text: "Upgrade from Micro to Mega Package[$30]", value: 1256},
              {text: "Upgrade from Micro to Business Package[$65]", value: 1258},
              {text: "Upgrade from Medium to Mega Package[$15]", value: 1259},
              {text: "Upgrade from Medium to Business Package[$55]", value: 1261},
              {text: "Upgrade from Mega to Business Package[$45]", value: 1262},
            ],
    showHeader:false
  },
  plugins: [
  
  createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    SET_SOMETHING(state, something){
      state.something = something;
    },
    SET_PRICE(state, price){
      state.price = price;
    },
    SET_MAIN(state, mainprice){
      state.mainprice = mainprice;
    },
    SET_UPGRADE(state, upgrade){
      state.upgrade = upgrade;
    },
    SET_SHOW_HEADER(state, showHeader){
      state.showHeader = showHeader;
    }
  },

  actions: {

    async initialize({commit}){
      commit("SET_SOMETHING", false);
    },

    async showheader({commit}){
      commit("SET_SHOW_HEADER", true);
    },

    async getprice({ commit}){
      commit("SET_SOMETHING", true);
      const price = await UserService.getprice()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302' || reStatus == '114'){
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                    } else {
                      return [];
                    }
                    });
      commit("SET_PRICE", price);
      const upradefiltered = await price.filter(obj => obj.product.includes('Upgrade'));
      const uprademapped = await upradefiltered.map(singleObj => {
            const rObj = {};
            rObj.text = singleObj.product+' - [ $'+singleObj.price+' ]';
            rObj.value = singleObj.id;
            return rObj;
            });
      commit("SET_UPGRADE", uprademapped);

      const mainmapped = await price.map(singleObj => {
            const rObj = {};
            rObj.text = singleObj.product+' - [ $'+singleObj.price+' ]';
            rObj.value = singleObj.product;
            return rObj;
            });
      commit("SET_MAIN", mainmapped);
    },

    async ordernow({commit},{product, referrer}){
          commit("SET_SOMETHING", true);
          const appUrl = await UserService.ordernow(product, referrer)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          return '';
                        } 
                        else if (reStatus == '111') {
                          return '';
                        }
                        else {
                          return response.data.response.result;
                        }
                    } else {
                      return '';
                    }
                    });
          
          window.location.replace(appUrl);               
    },
   
  },
  
  getters: {
    getPrice(state){
      return state.price;
    },
    getShowHeader(state){
      return state.showHeader;
    },

    getUpgrade(state){
      return state.upgrade;
    },
    getMainprice(state){
      return state.mainprice;
    }
  }
  
});